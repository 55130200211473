<template>
    <div class="purchase-container">
        <Card class="h-full">
            <div class="flex justify-between relative mb-4" >
                <h5 class="text-lg font-semibold relative right-0">Wire Accounts</h5>
                <Button text="OTC registration" @click="handleOTC"  class="mr-2" p="p-2" />
            </div>
            

            <Button  class="my-2 md:hidden" :loading="updateLoading" text="Filters" width="w-20" @click="isFilterVisible = !isFilterVisible" />
            <div class="flex justify-center" v-if="loading">
                <Spinner />
            </div>


            <div class="table-container" v-else >
                <div v-if="isFilterVisible || isMediumScreen">
                    <form class="flex gap-2 md:gap-1 items-start flex-wrap  md:items-center justify-start md:justify-end" @submit.stop="handleSearch" >
                       <!-- <div class="flex gap-2  flex-wrap items-start flex-col md:items-center md:flex-row  "> -->
                            <SearchInput placeholder="Email, business name" 
                        class="mr-2" v-model="params.searchQuery" p="p-3" width="w-64" />
                        <!-- <Card class="searchContainer w-44 mr-2" p="px-3"> -->
                            <Select width="w-48" class="mr-2" type="filter" 
                            placeholder="Forced KYC status" v-model="params.forcedKycStatus"
                                :options="forcedKycStatus" :reduce="(type) => type.value" />
                        <!-- </Card> -->
                        <!-- <Card class="searchContainer w-44 mr-2" p="px-3"> -->
                            <Select width="w-48" class="mr-2" type="filter" 
                            placeholder="Verification status" v-model="params.verificationStatus"
                                :options="verificationStatus" :reduce="(type) => type.value" />
                       
                        <!-- </Card> -->
                            <Button :outline="!params.getOnlyBrokers" 
                        @click="params.getOnlyBrokers = !params.getOnlyBrokers" text="Brokers only" class="mr-2"/>
                            <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                                        <button class="p-2 focus:outline-none" @click="togglePopover()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                                <path
                                                    d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                                                    fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                                <path
                                                    d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                                                    fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                        <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">
                                            Date filter
                                        </p>
                                        <p v-else @click="togglePopover()" class="text-sm text-grey">
                                            {{ inputValue.start }} - {{ inputValue.end }}
                                        </p>
                                    </Card>
                                </template>
                            </DatePicker>
                            <Button text="Apply filer"  class="mr-2" p="p-2" type="submit" />
                            <Button outline text="Clear filter" @click="clearSearch" p="p-2" type="button" />
                        <!-- </div> -->
                    </form>
                </div>
                <div class="overflow-x-auto">
                    <table id="template-table" class="w-full table-auto  min-w-[600px]">
                        <thead>
                            <tr>
                                <th class="text-xs  px-2 py-1">S/N</th>
                                <th class="text-xs  px-2 py-1">Business Name</th>
                                <th class="text-xs  px-2 py-1">Id</th>
                                <th class="text-xs  px-2 py-1">F. KYC</th>
                                <th class="text-xs  px-2 py-1 ">Acc. Status</th>
                                <th class="text-xs  px-2 py-1">V. Status</th>
                                <th class="text-xs  px-2 py-1">Email</th>
                                <!-- <th>Phone</th> -->
                                <!-- <th>Created Date</th> -->
                                <!-- <th>Mode</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <EmptyState class="mx-auto w-full my-10" description="No records found"
                                v-if="allRegistration.length === 0" />
                            <tr v-for="(data, index) in allRegistration" :key="data.id">
                                <td>{{ index + 1 }}</td>
                                <td class="capitalize text-elipsis break-all text-xs px-2 py-1">{{ data.wireAccountName }}</td>
                                <td class="text-xs px-2 py-1">{{ data.id }}</td>
                                <td class="capitalise text-xs px-2 py-1">{{ data.forcedKycStatus }}</td>
                                <td class="capitalise text-xs px-2 py-1" :class="{
                                    'text-green-1':data.accountStatus == 'ACTIVE',
                                    'text-red-1': data.accountStatus == 'DE_ACTIVATED' || data.accountStatus == 'BLOCKED' 
                                }">{{ data.accountStatus }}</td>
                                <td class="capitalize text-xs px-2 py-1">{{ data.verificationStatus || 'N/A' }}</td>
                                <td class="break-all text-xs px-2 py-1">{{ data.creatorEmail }}</td>
                                <!-- <td>{{ data.phoneNumber }}</td> -->
                                <!-- <td>{{ dayjs(data.createdAt).format("MMM D YYYY | hh:mmA") }}</td> -->
                                <td>
                                    <div class="flex items-center">
                                        <SecondaryButton text="Update Acc." width="w-24" @click="updateAccountInfo(data)"
                                            v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_account_update'))"
                                            class="mr-14 justify-self-end" />
                                        <!-- <SecondaryButton text="View Kyb" :disabled="!data.businessRegistrationInfoId" width="w-24" @click="updateKybModal(data)" /> -->
                                        <SecondaryButton text="View Acc." width="w-24"
                                            @click="$router.push(`/account/${data.id}`)" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div  class="text-left  float-left md:float-right "> -->
                        <Pagination v-if="showPagination"  :meta="pageMeta" :handleMore="handleMore" :loading="paginateLoading" @nextPage="handlePagination" />
                    <!-- </div> -->
                </div>
            </div>
        </Card>
        <div v-if="showModal">
            <UpdateWireAccount :wireInfo="singleWireInfo" :display="showModal" @close="showModal = false"
                @success="updateKybModal" />
        </div>
        <!-- <div v-if="showUpdateKybModal">
            <UpdateKyb :wireInfo="singleWireInfo" :display="showUpdateKybModal" @close="handleKybClose" />
        </div> -->
        <OTC @complete="handleOtcRegComplete" v-if="showOCT" :showOCT="showOCT"  @close="showOCT = false" />
    </div>
</template>
<script>
import { mapActions, mapState,mapGetters } from 'vuex'
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'
import UpdateWireAccount from './UpdateWireAccount.vue';
import Pagination from "@/UI/Pagination";
import { Select, SearchInput } from "@/UI/Input";
import { SecondaryButton, Button } from "@/UI/Button";
import { DatePicker } from "v-calendar";

import OTC from './OTC/OTC.vue';
export default {
    components: {
        Spinner,
        UpdateWireAccount,
        OTC,
        SecondaryButton,
        Pagination,
        SearchInput,
        Select,
        Button,
        DatePicker,
        EmptyState: () => import("@/UI/EmptyState"),
    },
    data: () => ({
        loading: false,
        showModal: false,
        showOCT: false,
        singleWireInfo: {},
        showUpdateKybModal: false,
        paginateLoading: false,
        handleMore: false,
        isFilterVisible: false,
        windowWidth: window.innerWidth,
        params: {
            page: 0,
            pageSize: 20,
            userEmail: "",
            from: "",
            to: "",
            verificationStatus: "",
            forcedKycStatus: "",
            getOnlyBrokers: false,
            searchQuery:''
        },
        verificationStatus: [
            { label: "All", value: "" },
            { label: "Approved", value: "APPROVED" },
            { label: "In Review", value: "IN_REVIEW" },
            { label: "Pending", value: "PENDING" },
            { label: "Declined", value: "DECLINED" },
        ],
        forcedKycStatus: [
            { label: "All", value: "" },
            { label: "On", value: "ON" },
            { label: "Off", value: "OFF" },
            { label: "Neutral", value: "NEUTRAL" },
        ],
        modelConfig: {
            type: "string",
            mask: "iso",
            start: {
                timeAdjust: "00:00:00",
            },
            end: {
                timeAdjust: "23:59:59",
            },
        },
        range: {
            start: "",
            end: "",
        },
        masks: {
            input: "DD MMM YY",
        },
        pageMeta: {},
        showPagination: false
    }),
    computed: {
        ...mapState({
            allRegistration: (state) => state.wire.allRegistrationData
        }),
        dayjs() {
            return dayjs
        },
        ...mapGetters('auth', ['getUserPermissions']),
        isMediumScreen() {
            // Returns true if screen width is 768px or larger (md breakpoint)
            return this.windowWidth >= 768;
        }
    },
    methods: {
        ...mapActions('wire', ['getAllRegistration']),
        handleGetAllRegistration(type) {
            type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
            this.getAllRegistration({ params: this.params, type: type })
                .then((res) => {
                    this.pageMeta = res.data.meta || {}
                    // if (res.data.data.length === this.params.pageSize) {
                    //     this.handleMore = true;
                    // } else {
                    //     this.handleMore = false;
                    // }
                    this.showPagination = res.data.data.length === this.params.pageSize ? true : false;
                    type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);

                }).catch(() => {
                    type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);

                })
        },
        updateAccountInfo(item) {
            this.singleWireInfo = item;
            this.showModal = true;
        },
        handleInfoClose() {
            this.showModal = false;
            this.handleGetAllRegistration()
        },
        updateKybModal(item) {
            this.singleWireInfo = item;
            this.showUpdateKybModal = true
        },
        handleKybClose() {
            this.showUpdateKybModal = false;
            this.handleGetAllRegistration()
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.handleGetAllRegistration("paginate");
        },
        clearSearch() {
            this.params = {
                page: 0,
                pageSize: 20,
                userEmail: "",
                from: "",
                to: "",
                verificationStatus: "",
                forcedKycStatus: "",
                searchQuery:'',
                getOnlyBrokers:false
            };
            this.$router.replace({
            ...this.$router.currentRoute,
            query: {},
            });
            this.range = {
                start: "",
                end: "",
            };
            this.handleGetAllRegistration();
        },
        handleSearch() {
            this.params.page = 0;
            this.params.from = this.range.start;
            this.params.to = this.range.end;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                ...this.params
                },
            });
            this.handleGetAllRegistration();
        },
        handleOTC(){
            this.showOCT=!this.showOCT
        },
        handleRemoveOTCForm(value){
            if(value === true){
                this.showOCT = false
            }
        },
        handleOtcRegComplete(){
            this.showOCT = false;
            this.handleGetAllRegistration()
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    },
    mounted() {
        if(Object.keys(this.$route.query).length){
            this.params = this.$route.query
        }
        this.handleGetAllRegistration()
        // }
        window.addEventListener('resize', this.handleResize);

    },
    beforeDestroy() {
        // Remove event listener when component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}

.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
}
</style>